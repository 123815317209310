import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '../../../../common/Button/Button';
import { getLabelFromValues } from '../../../../helpers/entityiTypeHelper';
import { entityTypeMapperObjectForPersonStep } from '../../../../helpers/Mappers';

const PAStepper = props => {
  const { steps, stepIndex, children, className, nextClick, addStepClick, onChangeIndex, ...restProps } = props;

  const applicationDetail = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication ?? {},
  );

  const { _id: clientId } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {},
  );

  const entityType = useMemo(
    () => applicationDetail?.company?.entityType?.value ?? 'PROPRIETARY_LIMITED',
    [applicationDetail?.company?.entityType],
  );

  const { generateApplicationSaveAndNextButtonLoaderAction } = useSelector(
    ({ generalLoaderReducer }) => generalLoaderReducer ?? false,
  );

  const [activeStep, setActiveStep] = useState(0);

  const onClickBackButton = useCallback(() => {
    onChangeIndex(activeStep - 1);
    setActiveStep(prevState => prevState - 1);
  }, [activeStep, setActiveStep, applicationDetail?._id, clientId]);

  const onClickNextButton = useCallback(async () => {
    try {
      const canGoNext = await nextClick();
      if (canGoNext && activeStep < steps.length - 1) {
        onChangeIndex(activeStep + 1);
        setActiveStep(prevState => prevState + 1);
      }
    } catch (e) {
      /**/
    }
  }, [steps, activeStep, setActiveStep, nextClick]);

  useEffect(() => {
    if (stepIndex !== activeStep) {
      setActiveStep(stepIndex);
    }
  }, [stepIndex, activeStep]);

  return (
    <div className={className} {...restProps}>
      <div className="stepper-container public">
        {steps?.map((step, index) => (
          <div
            key={step.text}
            className={`step-container ${activeStep === index && 'active-step'} ${index < activeStep && 'done-step'} ${
              step?.hidden && 'hidden'
            }`}
          >
            <span className={`material-icons-round arrow ${index < activeStep && 'done-step'} `}>
              keyboard_arrow_right
            </span>
            <div className="step">
              <span className="material-icons-round">{index < activeStep ? 'check_circle' : step.icon}</span>
              <span className="step-name">{step.text}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="step-content-wrapper public">
        <div className="step-content-name">{steps?.[activeStep]?.text}</div>
        <div className="step-content">{children}</div>
      </div>
      <div className="stepper-buttons-row">
        <div>
          {steps?.[activeStep]?.name === 'partners' && steps?.[activeStep]?.text !== 'Sole Trader' && (
            <Button
              buttonType="secondary"
              title={getLabelFromValues(entityType, entityTypeMapperObjectForPersonStep)}
              onClick={() => {
                addStepClick();
              }}
            />
          )}
        </div>
        <div className="d-flex">
          {activeStep > 0 && (
            <Button
              buttonType="outlined-primary"
              title={`Back to ${steps?.[activeStep - 1]?.text}`}
              onClick={onClickBackButton}
            />
          )}
          <Button
            className="ml-15"
            buttonType="primary"
            title={`Save${activeStep !== steps?.length - 1 ? ' and Next' : ''}`}
            onClick={onClickNextButton}
            isLoading={generateApplicationSaveAndNextButtonLoaderAction}
          />
        </div>
      </div>
    </div>
  );
};

PAStepper.propTypes = {
  className: PropTypes.string,
  stepIndex: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  nextClick: PropTypes.func,
  addStepClick: PropTypes.func,
  onChangeIndex: PropTypes.func,
  children: PropTypes.element.isRequired,
  isForDebtor: PropTypes.bool,
};

PAStepper.defaultProps = {
  className: '',
  onChangeIndex: () => {},
  nextClick: () => {},
  addStepClick: () => {},
  isForDebtor: false,
};

export default PAStepper;
