import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PublicApplicationBusinessDetailsStep from './component/PublicApplicationBusinessDetailsStep/PublicApplicationBusinessDetailsStep';
import {
  addPersonDetail,
  changeEditApplicationFieldValue,
  resetEditApplicationFieldValue,
} from './redux/PublicApplicationAction';
import { useQueryParams } from '../../hooks/GetQueryParamHook';
import Loader from '../../common/Loader/Loader';
import PAStepper from './component/components/PublicApplicationStepper';

import PublicApplicationInformationStep from './component/PublicApplicationInformationStep/PublicApplicationInformationStep';
import PublicApplicationContactDetailsStep from './component/PublicApplicationContactDetailsStep/PublicApplicationContactDetailsStep';
import PublicApplicationDirectorPrincipalDetailsStep from './component/PublicApplicationDirectorPrincipalDetailsStep/PublicApplicationDirectorPrincipalDetailsStep';
import PublicApplicationBusinessAddressesDetailsStep from './component/PublicApplicationBusinessAddressesDetailsStep/PublicApplicationBusinessAddressesDetailsStep';
import PublicApplicationConfirmationStep from './component/PublicApplicationConfirmationStep/PublicApplicationConfirmationStep';
import PublicApplicationAdditionalDocumentsStep from './component/PublicApplicationAdditionalDocumentsStep/PublicApplicationAdditionalDocumentsStep';

const STEP_COMPONENT = [
  <PublicApplicationInformationStep />,
  <PublicApplicationBusinessDetailsStep />,
  <PublicApplicationContactDetailsStep />,
  <PublicApplicationAdditionalDocumentsStep />,
  <PublicApplicationDirectorPrincipalDetailsStep />,
  <PublicApplicationBusinessAddressesDetailsStep />,
  <PublicApplicationConfirmationStep />,
];

const steps = [
  {
    icon: 'checklist_rtl',
    text: 'Introduction',
    name: 'introduction',
    hidden: true,
  },
  {
    icon: 'local_police',
    text: 'Business Details',
    name: 'businessDetails',
  },
  {
    icon: 'contact_page',
    text: 'Contact Details',
    name: 'contactDetails',
  },
  {
    icon: 'description',
    text: 'Additional Documents',
    name: 'additionalDocuments',
  },
  {
    icon: 'groups',
    text: 'Director/Principal Details',
    name: 'directorPrincipalDetails ',
  },
  {
    icon: 'add_location_alt',
    text: 'Business Addresses',
    name: 'businessAddresses',
  },
  {
    icon: 'list_alt',
    text: 'Confirm',
    name: 'confirm',
  },
];

const GeneratePublicApplication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { code } = useQueryParams();
  const { applicationStage, ...editApplicationData } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication ?? {},
  );

  // const { _id: clientId } = useSelector(
  //   ({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {},
  // );
  //
  // const { applicationId } = useQueryParams();

  const { generateApplicationPageLoaderAction } = useSelector(
    ({ generalLoaderReducer }) => generalLoaderReducer ?? false,
  );
  //
  // // for stepper components
  // const FILTERED_STEP_COMPONENT = useMemo(() => {
  //   const finalSteps = [...STEP_COMPONENT];
  //   if (!['PARTNERSHIP', 'TRUST', 'SOLE_TRADER'].includes(editApplicationData?.company?.entityType?.value ?? '')) {
  //     finalSteps.splice(1, 1);
  //   }
  //
  //   return finalSteps;
  // }, [editApplicationData?.company?.entityType, STEP_COMPONENT]);

  const onChangeIndex = useCallback(newIndex => {
    dispatch(changeEditApplicationFieldValue('applicationStage', newIndex));
  }, []);

  useEffect(async () => {
    return () => dispatch(resetEditApplicationFieldValue);
  }, []);

  // useEffect(() => {
  //   if (applicationId && clientId) {
  //     dispatch(getApplicationDetail(applicationId, clientId));
  //   }
  // }, [applicationId, clientId]);

  useEffect(() => {
    if (editApplicationData && editApplicationData?._id) {
      const params = {
        applicationId: editApplicationData?._id,
        code,
      };
      const url = Object.entries(params)
        .filter(arr => arr[1] !== undefined)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');

      history.replace(`${history.location.pathname}?${url}`);
    }
  }, [editApplicationData?._id, history]);

  const backToApplication = useCallback(() => {
    history.replace('/applications');
  }, [history]);

  const addStepClick = useCallback(() => {
    dispatch(addPersonDetail('individual'));
  }, []);

  const onNextClick = () => true;

  return (
    <>
      {!generateApplicationPageLoaderAction ? (
        <>
          <div className="breadcrumb-button-row">
            <div className="breadcrumb">
              <span onClick={backToApplication}>Application List</span>
              <span className="material-icons-round">navigate_next</span>
              <span>Generate Application</span>
            </div>
          </div>
          <PAStepper
            className="mt-10"
            steps={steps}
            stepIndex={applicationStage ?? 0}
            onChangeIndex={onChangeIndex}
            nextClick={onNextClick}
            addStepClick={addStepClick}
          >
            {STEP_COMPONENT[applicationStage ?? 0]}
          </PAStepper>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default GeneratePublicApplication;
