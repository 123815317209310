import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Input from '../../../../common/Input/Input';
import {
  getApplicationCompanyDropDownData,
  updateEditApplicationData,
  updateEditApplicationField,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import Select from '../../../../common/Select/Select';

const PublicApplicationDirectorPrincipalDetailsStep = () => {
  const dispatch = useDispatch();

  const directorPrincipalDetails = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.directorPrincipalDetails ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const { entityType } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.companyData?.dropdownData ?? {},
  );

  const INPUTS = useMemo(
    () => [
      {
        type: 'section',
        mainTitle: 'Director/Principal Details 1',
      },
      {
        label: 'Title',
        placeholder: 'Enter your title',
        type: 'text',
        name: 'title_one',
      },
      {
        label: 'Name*',
        placeholder: 'e.g. John Smith',
        type: 'text',
        name: 'name_one',
      },
      {
        label: 'Position*',
        placeholder: 'Enter your position',
        type: 'text',
        name: 'position_one',
      },
      {
        label: 'Date of Birth*',
        placeholder: '01234',
        type: 'date',
        name: 'dateOfBirth_one',
      },
      {
        label: 'Driver License*',
        placeholder: '(include state)',
        type: 'text',
        name: 'driverLicense_one',
      },
      {
        label: 'Email Address*',
        placeholder: 'your@email.com',
        type: 'text',
        name: 'email_one',
      },
      {
        label: 'Mobile*',
        placeholder: '1300 048 626',
        type: 'text',
        name: 'mobile_one',
      },

      {
        label: 'Address Line 1*',
        placeholder: 'Enter your address',
        type: 'text',
        name: 'addressLine1_one',
      },
      {
        label: 'Address Line 2',
        placeholder: 'Enter your address',
        type: 'text',
        name: 'addressLine2_one',
      },
      {
        label: 'City',
        placeholder: 'Enter your city',
        type: 'text',
        name: 'city_one',
      },
      {
        label: 'Postcode',
        placeholder: 'Enter your postcode',
        type: 'text',
        name: 'postcode_one',
      },
      {
        label: 'Country',
        placeholder: 'Enter your country',
        type: 'text',
        name: 'country_one',
      },
      {
        label: 'State',
        placeholder: 'Enter your state',
        type: 'text',
        name: 'state_one',
      },

      {
        type: 'section',
        mainTitle: 'Director/Principal Details 2',
      },
      {
        label: 'Title',
        placeholder: 'Enter your title',
        type: 'text',
        name: 'title_second',
      },
      {
        label: 'Name*',
        placeholder: 'e.g. John Smith',
        type: 'text',
        name: 'name_second',
      },
      {
        label: 'Position*',
        placeholder: 'Enter your position',
        type: 'text',
        name: 'position_second',
      },
      {
        label: 'Date of Birth*',
        placeholder: '01234',
        type: 'date',
        name: 'dateOfBirth_second',
      },
      {
        label: 'Driver License*',
        placeholder: '(include state)',
        type: 'text',
        name: 'driverLicense_second',
      },
      {
        label: 'Email Address*',
        placeholder: 'your@email.com',
        type: 'text',
        name: 'email_second',
      },
      {
        label: 'Mobile*',
        placeholder: '1300 048 626',
        type: 'text',
        name: 'mobile_second',
      },

      {
        label: 'Address Line 1*',
        placeholder: 'Enter your address',
        type: 'text',
        name: 'addressLine1_second',
      },
      {
        label: 'Address Line 2',
        placeholder: 'Enter your address',
        type: 'text',
        name: 'addressLine2_second',
      },
      {
        label: 'City',
        placeholder: 'Enter your city',
        type: 'text',
        name: 'city_second',
      },
      {
        label: 'Postcode',
        placeholder: 'Enter your postcode',
        type: 'text',
        name: 'postcode_second',
      },
      {
        label: 'Country',
        placeholder: 'Enter your country',
        type: 'text',
        name: 'country_second',
      },
      {
        label: 'State',
        placeholder: 'Enter your state',
        type: 'text',
        name: 'state_second',
      },
    ],
    [entityType, directorPrincipalDetails?.notFoundAddress, _id],
  );

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('directorPrincipalDetails', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    data => {
      updateSingleApplicationState(data?.name, data);
    },
    [updateSingleApplicationState],
  );

  const onChangeDate = useCallback(
    (name, date) => {
      updateSingleApplicationState(name, date);
    },
    [updateSingleApplicationState],
  );

  const getComponentFromType = useCallback(
    input => {
      let component = null;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={directorPrincipalDetails[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'search':
          component = (
            <Input
              type="text"
              name={input.name}
              suffix="search"
              suffixClass="application-search-suffix"
              suffixClick={() => {}}
              placeholder={input.placeholder}
              value={directorPrincipalDetails?.[input.name] ?? ''}
              onChange={handleTextInputChange}
              onKeyDown={() => {}}
            />
          );
          break;
        case 'select': {
          component = (
            <Select
              placeholder={input.placeholder}
              name={input?.name}
              options={input?.data ?? []}
              isSearchable
              value={directorPrincipalDetails?.[input?.name] ?? []}
              onChange={handleSelectInputChange}
              onInputChange={input?.onInputChange}
            />
          );
          break;
        }
        case 'date':
          component = (
            <div className="date-picker-container">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText={input.placeholder}
                selected={directorPrincipalDetails?.[input?.name] && new Date(directorPrincipalDetails?.[input?.name])}
                onChange={date => onChangeDate(input?.name, date)}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                popperProps={{ positionFixed: true }}
              />
              <span className="material-icons-round">event</span>
            </div>
          );
          break;
        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.name}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          {component}
          {directorPrincipalDetails?.errors?.[input?.name] && (
            <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>
              {directorPrincipalDetails?.errors?.[input?.name]}
            </div>
          )}
        </React.Fragment>
      );
    },
    [directorPrincipalDetails, handleSelectInputChange, handleTextInputChange, updateSingleApplicationState],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getApplicationCompanyDropDownData(_id)).catch(() => {
        errorNotification('Error during fetching data');
      });
    }

    return () => dispatch(updateEditApplicationData('company', { errors: {} }));
  }, [_id]);

  return (
    <>
      <div className="application-company-container">{INPUTS?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationDirectorPrincipalDetailsStep;
