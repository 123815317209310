import ApiService from '../../../services/api-service/ApiService';
import { PUBLIC_APPLICATION_ANONYMOUS_URLS } from '../../../constants/UrlConstants';

const PublicApplicationDocumentStepApiServices = {
  getApplicationDocumentDataList: (id, params) =>
    ApiService.getData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.DOCUMENTS.DOCUMENTS_LIST}${id}`, { params }),
  getDocumentTypeListData: params =>
    ApiService.getData(PUBLIC_APPLICATION_ANONYMOUS_URLS.DOCUMENTS.GET_DOCUMENT_TYPE_LIST_URL, { params }),
  uploadDocument: (data, config, clientId) =>
    ApiService.request({
      url: `${PUBLIC_APPLICATION_ANONYMOUS_URLS.DOCUMENTS.UPLOAD_DOCUMENT_URL}?clientId=${clientId}`,
      data,
      config,
      method: 'POST',
      timeout: 60000,
    }),
  deleteApplicationDocument: id =>
    ApiService.deleteData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.DOCUMENTS.APPLICATION_DELETE_DOCUMENT}${id}`),
};
export default PublicApplicationDocumentStepApiServices;
