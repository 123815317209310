import ApiService from '../../../services/api-service/ApiService';
import { PUBLIC_APPLICATION_ANONYMOUS_URLS } from '../../../constants/UrlConstants';

const PublicApplicationApiServices = {
  saveApplicationStepDataToBackend: (data, clientId) =>
    ApiService.putData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.APPLICATION_SAVE_STEP_DATA}?clientId=${clientId}`, data),
  getApplicationDetail: (appId, clientId) =>
    ApiService.getData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.GET_APPLICATION_DETAILS_URL}${appId}?clientId=${clientId}`),
  getEntitiesBySearch: params =>
    ApiService.getData(PUBLIC_APPLICATION_ANONYMOUS_URLS.SEARCH_APPLICATION_ENTITY, { params }),
};
export default PublicApplicationApiServices;
