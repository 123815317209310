import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../common/Input/Input';
import {
  getApplicationCompanyDropDownData,
  updateEditApplicationData,
  updateEditApplicationField,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import Select from '../../../../common/Select/Select';
import FileUpload from '../../../../common/Header/component/FileUpload';

const PublicApplicationAdditionalDocumentsStep = () => {
  const dispatch = useDispatch();

  const documents = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.documents ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const { entityType } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.companyData?.dropdownData ?? {},
  );

  const INPUTS = useMemo(
    () => [
      {
        mainTitle: 'Documents',
        type: 'section',
      },
      {
        title:
          'Please download Deed Poll of guarantee and indemnity form, sign and upload into the below box. Form available here',
        type: 'title',
      },
      { label: 'Please upload your documents here', type: 'fileUpload', name: 'deedPollOfGuaranteeAndIndemnity' },
      {
        type: 'line',
      },
      {
        title: 'Please upload a copy of Drivers Licence or Passport',
        type: 'title',
      },

      {
        label: 'Please upload your Drivers Licence or Passport here',
        type: 'fileUpload',
        name: 'driversLicenceOrPassport',
      },

      {
        type: 'line',
      },
      {
        label: 'Director Identification Number',
        title: 'Director Identification Number',
        type: 'text',
        name: 'directorIdentificationNumber',
      },
    ],
    [entityType, document?.notFoundAddress, _id],
  );

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('documents', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    data => {
      updateSingleApplicationState(data?.name, data);
    },
    [updateSingleApplicationState],
  );

  const onUploadClick = useCallback((name, e) => {
    // e.persist();
    if (e.target.files && e.target.files.length > 0) {
      const fileExtension = [
        'jpeg',
        'jpg',
        'png',
        'bmp',
        'gif',
        'tex',
        'xls',
        'xlsx',
        'csv',
        'doc',
        'docx',
        'odt',
        'txt',
        'pdf',
        'png',
        'pptx',
        'ppt',
        'rtf',
      ];
      const mimeType = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'image/bmp',
        'image/gif',
        'application/x-tex',
        'application/vnd.ms-excel',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.text',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'application/rtf',
      ];
      const checkExtension = fileExtension.indexOf(e.target.files[0].name.split('.').splice(-1)[0]) !== -1;
      const checkMimeTypes = mimeType.indexOf(e.target.files[0].type) !== -1;

      const checkFileSize = e.target.files[0].size > 10485760;
      if (!(checkExtension || checkMimeTypes)) {
        errorNotification('Invalid File Type.');
      } else if (checkFileSize) {
        errorNotification('File size should be less than 10MB.');
      } else {
        updateSingleApplicationState(name, e.target.files[0].name);
      }
    }
  }, []);

  const getComponentFromType = useCallback(
    input => {
      let component = null;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={documents[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'search':
          component = (
            <Input
              type="text"
              name={input.name}
              suffix="search"
              suffixClass="application-search-suffix"
              suffixClick={() => {}}
              placeholder={input.placeholder}
              value={documents?.[input.name] ?? ''}
              onChange={handleTextInputChange}
              onKeyDown={() => {}}
            />
          );
          break;
        case 'select': {
          component = (
            <Select
              placeholder={input.placeholder}
              name={input?.name}
              options={input?.data ?? []}
              isSearchable
              value={documents?.[input?.name] ?? []}
              onChange={handleSelectInputChange}
              onInputChange={input?.onInputChange}
            />
          );
          break;
        }
        case 'fileUpload': {
          component = (
            <FileUpload
              isProfile={false}
              fileName={documents?.[input?.name] ?? 'Browse...'}
              handleChange={e => onUploadClick(input?.name, e)}
            />
          );
          break;
        }

        case 'title': {
          component = <div className="application-company-step-title">{input?.title}</div>;
          break;
        }

        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.label}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          {component}
          {documents?.errors?.[input?.name] && (
            <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>{documents?.errors?.[input?.name]}</div>
          )}
        </React.Fragment>
      );
    },
    [documents, handleSelectInputChange, handleTextInputChange, updateSingleApplicationState],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getApplicationCompanyDropDownData(_id)).catch(() => {
        errorNotification('Error during fetching data');
      });
    }

    return () => dispatch(updateEditApplicationData('company', { errors: {} }));
  }, [_id]);

  return (
    <>
      <div className="application-company-container ">{INPUTS?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationAdditionalDocumentsStep;
