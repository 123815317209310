import ApiService from '../../../services/api-service/ApiService';
import { PUBLIC_APPLICATION_ANONYMOUS_URLS } from '../../../constants/UrlConstants';

const PublicApplicationCompanyStepApiServices = {
  getApplicationCompanyStepDropdownData: clientId =>
    ApiService.getData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.COMPANY.DROP_DOWN_DATA_URL}?clientId=${clientId}`),
  getApplicationCompanyDataFromDebtor: (id, clientId) =>
    ApiService.getData(
      `${PUBLIC_APPLICATION_ANONYMOUS_URLS.COMPANY.SEARCH_APPLICATION_BY_DEBTOR_DETAILS}${id}/?clientId=${clientId}`,
    ),
  getApplicationCompanyDataFromABNorACN: params =>
    ApiService.getData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.COMPANY.SEARCH_APPLICATION_BY_ABN_ACN_DETAILS}`, {
      params,
    }),
  searchApplicationCompanyEntityName: params =>
    ApiService.getData(`${PUBLIC_APPLICATION_ANONYMOUS_URLS.COMPANY.SEARCH_APPLICATION_ENTITY_TYPE}`, {
      params,
    }),
  generateRandomRegistrationNumber: params =>
    ApiService.getData(PUBLIC_APPLICATION_ANONYMOUS_URLS.COMPANY.GENERATE_RANDOM_REGISTRATION_NUMBER, { params }),
};
export default PublicApplicationCompanyStepApiServices;
