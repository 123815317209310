import PublicApplicationApiServices from '../services/PublicApplicationApiServices';
import { errorNotification, successNotification } from '../../../common/Toast';
import { APPLICATION_REDUX_CONSTANTS } from './PublicApplicationReduxConstants';
import PublicApplicationCompanyStepApiServices from '../services/PublicApplicationCompanyStepApiServices';
import PublicApplicationDocumentStepApiServices from '../services/PublicApplicationDocumentStepApiServices';
import { displayErrors } from '../../../helpers/ErrorNotifyHelper';
import {
  startGeneralLoaderOnRequest,
  stopGeneralLoaderOnSuccessOrFail,
} from '../../../common/GeneralLoader/redux/GeneralLoaderAction';

export const getApplicationDetail = (applicationId, clientId) => {
  return async dispatch => {
    try {
      startGeneralLoaderOnRequest('generateApplicationPageLoaderAction');
      const response = await PublicApplicationApiServices.getApplicationDetail(applicationId, clientId);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.APPLICATION_DETAILS,
          data: response?.data?.data,
        });
        stopGeneralLoaderOnSuccessOrFail('generateApplicationPageLoaderAction');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('generateApplicationPageLoaderAction');
      displayErrors(e);
    }
  };
};

export const getApplicationCompanyDropDownData = clientId => {
  return async dispatch => {
    try {
      const response = await PublicApplicationCompanyStepApiServices.getApplicationCompanyStepDropdownData(clientId);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_DROP_DOWN_DATA,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getApplicationCompanyDataFromDebtor = (id, clientId) => {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await PublicApplicationCompanyStepApiServices.getApplicationCompanyDataFromDebtor(id, clientId);

      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_WIPE_OUT_OLD_DATA_ON_SUCCESS,
          isDebtor: true,
        });
        return response.data;
      }
    } catch (e) {
      throw e;
    }
    return null;
  };
};

export const getApplicationCompanyDataFromABNOrACN = params => {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await PublicApplicationCompanyStepApiServices.getApplicationCompanyDataFromABNorACN({
        ...params,
        step: 'company',
      });

      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_WIPE_OUT_OLD_DATA_ON_SUCCESS,
        });
        return response.data;
      }
    } catch (e) {
      throw e;
    }
    return null;
  };
};

export const searchApplicationCompanyEntityName = params => {
  return async dispatch => {
    try {
      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_ENTITY_TYPE_DATA,
        data: {
          isLoading: params?.page === 0 && true,
          error: false,
          errorMessage: '',
        },
      });
      const response = await PublicApplicationCompanyStepApiServices.searchApplicationCompanyEntityName(params);

      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_ENTITY_TYPE_DATA,
          data: {
            isLoading: false,
            error: false,
            errorMessage: '',
            data: response?.data?.data,
          },
        });
      }
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response?.data?.status === undefined) {
          errorNotification('It seems like server is down, Please try again later.');
        } else if (e.response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          errorNotification('Internal server error');
        } else {
          dispatch({
            type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_ENTITY_TYPE_DATA,
            data: {
              isLoading: false,
              error: true,
              errorMessage: e.response.data.message ?? 'Please try again later.',
            },
          });
        }
      } else {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_COMPANY_ENTITY_TYPE_DATA,
          data: {
            isLoading: false,
            error: true,
            errorMessage: 'ABR Lookup is not responding, please try again.',
            data: [],
          },
        });
      }
    }
  };
};

export const resetEntityTableData = () => {
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.COMPANY.WIPE_OUT_ENTITY_TABLE_DATA,
    });
  };
};

export const changeEditApplicationFieldValue = (name, value) => {
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.APPLICATION_COMPANY_EDIT_APPLICATION_CHANGE_FIELD_VALUE,
      name,
      value,
    });
  };
};

export const resetEditApplicationFieldValue = {
  type: APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.APPLICATION_COMPANY_EDIT_APPLICATION_RESET_DATA,
};

export const updateEditApplicationData = (stepName, data) => {
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.APPLICATION_COMPANY_EDIT_APPLICATION_UPDATE_ALL_DATA,
      stepName,
      data,
    });
  };
};

export const updateEditApplicationField = (stepName, name, value) => {
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.EDIT_APPLICATION.REPLICATED_APPLICATION_UPDATE_FIELD,
      stepName,
      name,
      value,
    });
  };
};

// for person step
export const addPersonDetail = type => {
  const companyData = {
    type: 'company',
    stakeholderCountry: [],
    abn: '',
    acn: '',
    entityType: '',
    entityName: '',
    tradingName: '',
    errors: {},
  };

  const individualData = {
    type: 'individual',
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    driverLicenceNumber: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    allowToCheckCreditHistory: false,
    property: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    state: '',
    country: '',
    postCode: '',
    stakeholderCountry: {
      label: 'Australia',
      name: 'country',
      value: 'AUS',
    },
    errors: {},
  };
  const data = type === 'individual' ? individualData : companyData;
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.PERSON.ADD_APPLICATION_PERSON,
      data,
    });
  };
};

// person step edit application
export const getApplicationPersonDataFromABNOrACN = params => {
  return async () => {
    try {
      const response = await PublicApplicationCompanyStepApiServices.getApplicationCompanyDataFromABNorACN({
        ...params,
        step: 'person',
      });

      if (response?.data?.status === 'SUCCESS') {
        return response?.data?.data;
      }
    } catch (e) {
      displayErrors(e);
      throw Error();
    }
    return null;
  };
};

export const updatePersonData = (index, name, value) => {
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.PERSON.EDIT_APPLICATION_PERSON,
      index,
      name,
      value,
    });
  };
};
// dispatch this when radio button change from indi to company
export const changePersonType = (index, personType) => {
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.PERSON.CHANGE_APPLICATION_PERSON_TYPE,
      index,
      personType,
    });
  };
};

export const updatePersonStepDataOnValueSelected = (index, data) => {
  return dispatch => {
    dispatch({
      type: APPLICATION_REDUX_CONSTANTS.PERSON.PERSON_STEP_COMPANY_EDIT_APPLICATION_UPDATE_ALL_DATA,
      index,
      data,
    });
  };
};

export const saveApplicationStepDataToBackend = (data, clientId) => {
  return async dispatch => {
    try {
      startGeneralLoaderOnRequest('generateApplicationSaveAndNextButtonLoaderAction');
      const response = await PublicApplicationApiServices.saveApplicationStepDataToBackend(data, clientId);
      if (response?.data?.status === 'SUCCESS') {
        if (response?.data?.data?.applicationStage) {
          const { _id, applicationId } = response?.data?.data;
          dispatch(changeEditApplicationFieldValue('_id', _id));
          dispatch(changeEditApplicationFieldValue('applicationId', applicationId));
          localStorage.setItem('application_id', _id);
        }
        successNotification(response?.data?.message || 'Application step saved successfully');
        stopGeneralLoaderOnSuccessOrFail('generateApplicationSaveAndNextButtonLoaderAction');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('generateApplicationSaveAndNextButtonLoaderAction');
      if (e.response?.data?.messageCode === 'APPLICATION_ALREADY_EXISTS') {
        errorNotification(e?.response?.data?.message ?? 'Application already exist');
        throw Error();
      } else if (e.response?.data?.messageCode === 'REQUIRE_FIELD_MISSING') {
        errorNotification(e?.response?.data?.message ?? 'Required field armissing');
        throw Error();
      } else {
        displayErrors(e);
        throw Error();
      }
    }
  };
};

// document
export const getApplicationDocumentDataList = (id, clientId, params = { page: 1, limit: 15 }) => {
  return async dispatch => {
    try {
      const updateParams = {
        ...params,
        documentFor: 'application',
        clientId,
      };
      const response = await PublicApplicationDocumentStepApiServices.getApplicationDocumentDataList(id, updateParams);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.DOCUMENTS.APPLICATION_DOCUMENT_GET_UPLOAD_DOCUMENT_DATA,
          data: response?.data?.data && response?.data?.data.docs ? response?.data?.data.docs : [],
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const getDocumentTypeList = clientId => {
  return async dispatch => {
    try {
      const params = {
        listFor: 'application',
        clientId,
      };
      const response = await PublicApplicationDocumentStepApiServices.getDocumentTypeListData(params);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.DOCUMENTS.DOCUMENT_TYPE_LIST_DATA,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};
export const uploadDocument = (data, config, clientId) => {
  return async dispatch => {
    try {
      const response = await PublicApplicationDocumentStepApiServices.uploadDocument(data, config, clientId);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.DOCUMENTS.UPLOAD_DOCUMENT_DATA,
          data: response?.data?.data,
        });
      }
    } catch (e) {
      throw e?.response?.data?.message;
    }
  };
};

export const deleteApplicationDocumentAction = async (appDocId, cb) => {
  try {
    startGeneralLoaderOnRequest('GenerateApplicationDocumentDeleteButtonLoaderAction');
    const response = await PublicApplicationDocumentStepApiServices.deleteApplicationDocument(appDocId);
    if (response?.data?.status === 'SUCCESS') {
      successNotification(response?.data?.message || 'Application document deleted successfully.');
      stopGeneralLoaderOnSuccessOrFail('GenerateApplicationDocumentDeleteButtonLoaderAction');
      if (cb) {
        cb();
      }
    }
  } catch (e) {
    stopGeneralLoaderOnSuccessOrFail('GenerateApplicationDocumentDeleteButtonLoaderAction');
    displayErrors(e);
  }
};

export const getApplicationCompanyStepDropDownDataBySearch = options => {
  return async dispatch => {
    try {
      const response = await PublicApplicationApiServices.getEntitiesBySearch(options);

      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.COMPANY.APPLICATION_SEARCH_DROP_DOWN_DATA,
          data: response?.data?.data,
          name: options.entityType,
        });
      }
    } catch (e) {
      displayErrors(e);
    }
  };
};

export const generateRandomRegistrationNumber = params => {
  return async dispatch => {
    try {
      const response = await PublicApplicationCompanyStepApiServices.generateRandomRegistrationNumber(params);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.COMPANY.SET_RANDOM_GENERATED_REGISTRATION_NUMBER,
          data: response.data.data,
        });
        return response.data.data;
      }
    } catch (e) {
      displayErrors(e);
    }
    return false;
  };
};

export const getApplicationDetailsOnBackToCompanyStep = (applicationId, activeStep, clientId) => {
  return async dispatch => {
    try {
      startGeneralLoaderOnRequest('generateApplicationPageLoaderAction');
      const response = await PublicApplicationApiServices.getApplicationDetail(applicationId, clientId);
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.UPDATE_APPLICATION_DETAILS_ON_BACK_TO_COMPANY_STEP,
          data: response?.data?.data,
          activeStep,
        });
        stopGeneralLoaderOnSuccessOrFail('generateApplicationPageLoaderAction');
      }
    } catch (e) {
      stopGeneralLoaderOnSuccessOrFail('generateApplicationPageLoaderAction');
      displayErrors(e);
    }
  };
};
