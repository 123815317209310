/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import Checkbox from '../../../../common/Checkbox/Checkbox';
import RadioButton from '../../../../common/RadioButton/RadioButton';

const PublicApplicationConfirmationStep = () => {
  // const editApplication = useSelector(({ application }) => application.editApplication);

  const {
    businessDetails,
    contactDetails,
    businessAddressesDetails,
    directorPrincipalDetails,
    creditLimit,
    partners,
    documents,
  } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication ?? {});

  const confirmationDetails = [
    {
      title: 'Business Details',
      type: 'main-title',
    },
    {
      title: 'Business',
      type: 'title',
    },
    {
      title: 'Sales Rep Name',
      value: businessDetails?.salesRepName || false,
      label: 'salesRepName',
      type: 'text',
    },
    {
      title: 'Type of Account',
      value: businessDetails?.typeOfAccount.label || false,
      label: 'typeOfAccount',
      type: 'text',
    },
    {
      title: 'Business Numbers',
      value: `ABN ${businessDetails?.abn}` || false,
      label: 'abn',
      type: 'text',
    },
    {
      title: 'ACN',
      value: businessDetails?.acn || false,
      label: 'acn',
      type: 'text',
    },
    {
      title: 'Business structure',
      value: businessDetails?.businessStructure.label || false,
      label: 'businessStructure',
      type: 'text',
    },
    {
      title: 'Year of inception',
      value: businessDetails?.yearOfInception || false,
      label: 'yearOfInception',
      type: 'text',
    },

    {
      title: 'Are You a New Applicant or an Existing Customer?',
      value: businessDetails?.newOrExistingApplicant.label || false,
      label: 'newOrExistingApplicant',
      type: 'text',
    },
    {
      title: 'How Did You Hear About us?',
      value: businessDetails?.reference.label || false,
      label: 'reference',
      type: 'text',
    },

    {
      title: 'Names',
      type: 'title',
    },
    {
      title: 'Legal Entity Name',
      value: businessDetails?.legalEntityName || false,
      label: 'legalEntityName',
      type: 'text',
    },
    {
      title: 'Main trading name',
      value: businessDetails?.mainTradingName || false,
      label: 'mainTradingName',
      type: 'text',
    },
    {
      title: 'Other trading names',
      value: businessDetails?.otherTradingNames || false,
      label: 'otherTradingNames',
      type: 'text',
    },

    {
      title: 'Account Details',
      type: 'title',
    },
    {
      title: 'Estimated Monthly Purchases',
      value: businessDetails?.estimatedMonthlyPurchases || false,
      label: 'estimatedMonthlyPurchases',
      type: 'text',
    },
    {
      title: 'Customer Group: eg Builder',
      value: businessDetails?.customerGroup || false,
      label: 'customerGroup',
      type: 'text',
    },
    {
      title: 'Home Branch',
      value: businessDetails?.homeBranch.value || false,
      label: 'homeBranch',
      type: 'text',
    },
    {
      title: 'Offer Code',
      value: businessDetails?.offerCode || false,
      label: 'offerCode',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Contact Details',
      type: 'main-title',
    },
    {
      title: 'Primary Contact',
      type: 'title',
    },
    {
      title: 'Full name',
      value: contactDetails?.primaryFullName || false,
      label: 'primaryFullName',
      type: 'text',
    },
    {
      title: 'Position',
      value: contactDetails?.primaryPosition || false,
      label: 'primaryPosition',
      type: 'text',
    },
    {
      title: 'Email',
      value: contactDetails?.primaryEmail || false,
      label: 'primaryEmail',
      type: 'text',
    },
    {
      title: 'Phone',
      value: contactDetails?.primaryPhone || false,
      label: 'primaryPhone',
      type: 'text',
    },

    {
      title: 'Accounts Payable Contact',
      type: 'title',
    },
    {
      title: 'Full name',
      value: contactDetails?.payableFullName || false,
      label: 'payableFullName',
      type: 'text',
    },

    {
      title: 'Email',
      value: contactDetails?.payableFullName || false,
      label: 'payableEmail',
      type: 'text',
    },
    {
      title: 'Phone',
      value: contactDetails?.payablePhone || false,
      label: 'payablePhone',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Additional Documents',
      type: 'main-title',
    },
    {
      title: 'Documents',
      type: 'title',
    },
    {
      title: 'Director Identification Number',
      value: documents?.directorIdentificationNumber || false,
      label: 'directorIdentificationNumber',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Director/Principal Details',
      type: 'main-title',
    },
    {
      title: 'Director/Principal Details 1',
      type: 'title',
    },
    {
      title: 'Name',
      value: directorPrincipalDetails?.title_one || false,
      label: 'title_one',
      type: 'text',
    },
    {
      title: 'Position',
      value: directorPrincipalDetails?.position_one || false,
      label: 'position_one',
      type: 'text',
    },
    {
      title: 'Mobile',
      value: directorPrincipalDetails?.mobile_one || false,
      label: 'mobile_one',
      type: 'text',
    },
    {
      title: 'Email',
      value: directorPrincipalDetails?.email_one || false,
      label: 'email_one',
      type: 'text',
    },
    {
      title: 'Address 1',
      value: directorPrincipalDetails?.addressLine1_one || false,
      label: 'addressLine1_one',
      type: 'text',
    },
    {
      title: 'Address 2',
      value: directorPrincipalDetails?.addressLine2_one || false,
      label: 'addressLine2_one',
      type: 'text',
    },
    {
      title: 'City',
      value: directorPrincipalDetails?.city_one || false,
      label: 'city_one',
      type: 'text',
    },
    {
      title: 'Postcode',
      value: directorPrincipalDetails?.postcode_one || false,
      label: 'postcode_one',
      type: 'text',
    },
    {
      title: 'Country',
      value: directorPrincipalDetails?.country_one || false,
      label: 'country_one',
      type: 'text',
    },
    {
      title: 'State',
      value: directorPrincipalDetails?.state_one || false,
      label: 'state_one',
      type: 'text',
    },
    {
      title: 'Director/Principal Details 2',
      type: 'title',
    },
    {
      title: 'Name',
      value: directorPrincipalDetails?.title_second || false,
      label: 'title_second',
      type: 'text',
    },
    {
      title: 'Position',
      value: directorPrincipalDetails?.position_second || false,
      label: 'position_second',
      type: 'text',
    },
    {
      title: 'Mobile',
      value: directorPrincipalDetails?.mobile_second || false,
      label: 'mobile_second',
      type: 'text',
    },
    {
      title: 'Email',
      value: directorPrincipalDetails?.email_second || false,
      label: 'email_second',
      type: 'text',
    },
    {
      title: 'Address 1',
      value: directorPrincipalDetails?.addressLine1_second || false,
      label: 'addressLine1_second',
      type: 'text',
    },
    {
      title: 'Address 2',
      value: directorPrincipalDetails?.addressLine2_second || false,
      label: 'addressLine2_second',
      type: 'text',
    },
    {
      title: 'City',
      value: directorPrincipalDetails?.city_second || false,
      label: 'city_second',
      type: 'text',
    },
    {
      title: 'Postcode',
      value: directorPrincipalDetails?.postcode_second || false,
      label: 'postcode_second',
      type: 'text',
    },
    {
      title: 'Country',
      value: directorPrincipalDetails?.country_second || false,
      label: 'country_second',
      type: 'text',
    },
    {
      title: 'State',
      value: directorPrincipalDetails?.state_second || false,
      label: 'state_second',
      type: 'text',
    },
    {
      type: 'line',
    },
    {
      title: 'Registered Address',
      type: 'main-title',
    },
    {
      title: 'Address 1',
      value: businessAddressesDetails?.addressLine1,
      type: 'text',
    },
    {
      title: 'Address 2',
      value: businessAddressesDetails?.addressLine2,
      type: 'text',
    },
    {
      title: 'City',
      value: businessAddressesDetails?.city,
      type: 'text',
    },
    {
      title: 'Postcode',
      value: businessAddressesDetails?.postcode,
      type: 'text',
    },
    {
      title: 'Country',
      value: businessAddressesDetails?.country,
      type: 'text',
    },
    {
      title: 'State',
      value: businessAddressesDetails?.state,
      type: 'text',
    },
  ];

  const getConfirmationComponentFromType = useCallback(
    detail => {
      switch (detail.type) {
        case 'text':
          return (
            <>
              <span className="application-confirmation-title">{detail?.title}</span>
              <span className="detail-value">{detail?.value}</span>
            </>
          );
        case 'select':
          return (
            <>
              <span className="application-confirmation-title">{detail?.title}</span>
              <span className="detail-value">{detail?.value?.label}</span>
            </>
          );
        case 'ifYesText':
          return (
            <>
              <span className="application-confirmation-title">{detail.title}</span>
              <span className="long-text">{detail.value}</span>
            </>
          );
        case 'title':
          return (
            <>
              <span className="title">{detail.title}</span>
            </>
          );
        case 'main-title-director-details':
          return (
            <>
              <span className="main-title-director-details">{detail.title}</span>
            </>
          );
        case 'radio':
          return (
            <>
              <span className="radio-title">{detail.title}</span>
              <span className="radio-buttons">
                <RadioButton
                  disabled
                  id={`${detail.id}-yes`}
                  name={detail.name}
                  label="Yes"
                  value
                  checked={detail.value}
                />
                <RadioButton
                  disabled
                  id={`${detail.id}-no`}
                  name={detail.name}
                  label="No"
                  value={false}
                  checked={!detail.value}
                />
              </span>
            </>
          );
        case 'checkbox':
          return (
            <>
              <Checkbox className="grid-checkbox" title={detail.title} disabled={detail.isDisabled} />
            </>
          );
        case 'main-title':
          return (
            <>
              <div className="main-title">{detail.title}</div>
            </>
          );
        case 'line':
          return <div className="horizontal-line" />;
        case 'blank':
          return (
            <>
              <div />
              <div />
            </>
          );
        case 'array':
          return detail?.data?.map(elem => elem.map(getConfirmationComponentFromType));
        default:
          return null;
      }
    },
    [partners],
  );
  return (
    <div className="application-confirmation-step">{confirmationDetails?.map(getConfirmationComponentFromType)}</div>
  );
};

export default PublicApplicationConfirmationStep;
