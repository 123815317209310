import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/GetQueryParamHook';
import PublicDebtorApplicationForm from './GeneratePublicApplication';
import { getAnonymousClientDetails } from '../CompanyProfile/redux/CompanyProfileAction';
import Loader from '../../common/Loader/Loader';

const PublicApplicationWrapper = () => {
  const { code, applicationId } = useQueryParams();
  const history = useHistory();
  const { clientDetail } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated ?? {});
  const { getPublicClientDetailsActionLoader } = useSelector(
    ({ generalLoaderReducer }) => generalLoaderReducer ?? false,
  );

  if (!code) {
    history.push('/404');
  }
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Credit Application';
    if (code) {
      const clientId = window.atob(code ?? '');
      dispatch(getAnonymousClientDetails(clientId));
      const storedApplicationId = localStorage.getItem('application_id');
      if (!applicationId && storedApplicationId) {
        history.push(`/credit-application?code=${code}&applicationId=${storedApplicationId}`);
      } else if (applicationId) {
        localStorage.setItem('application_id', applicationId);
      }
    }
  }, []);

  return (
    <div className="public-layout-container">
      {getPublicClientDetailsActionLoader ? (
        <Loader />
      ) : (
        <>
          <div className="public-company-info">
            <img
              className="copany-logo"
              src={clientDetail?.profile ?? `http://${window.location.host}/static/media/logo.58f1ed25.svg`}
            />
            <div>{clientDetail?.name}</div>
            <div className="subtitle">Fill in the required details to move forward with your application</div>
          </div>
          <PublicDebtorApplicationForm />
        </>
      )}
    </div>
  );
};

export default PublicApplicationWrapper;
