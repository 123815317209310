import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Input from '../../../../common/Input/Input';
import {
  getApplicationCompanyDropDownData,
  updateEditApplicationData,
  updateEditApplicationField,
} from '../../redux/PublicApplicationAction';
import { errorNotification } from '../../../../common/Toast';
import Select from '../../../../common/Select/Select';

const PublicApplicationBusinessDetailsStep = () => {
  const dispatch = useDispatch();
  const businessDetails = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.editApplication?.businessDetails ?? {},
  );

  const { _id } = useSelector(({ publicApplicationReplicated }) => publicApplicationReplicated?.clientDetail ?? {});

  const { entityType } = useSelector(
    ({ publicApplicationReplicated }) => publicApplicationReplicated?.companyData?.dropdownData ?? {},
  );

  const INPUTS = useMemo(
    () => [
      {
        type: 'section',
        mainTitle: 'Business',
      },
      {
        label: 'Sales Rep Name',
        placeholder: 'Sales Rep Name',
        type: 'text',
        name: 'salesRepName',
      },
      {
        label: 'Type of Account',
        placeholder: 'Type of Account',
        type: 'select',
        name: 'typeOfAccount',
        data: [
          { label: 'Cash', name: 'typeOfAccount', value: 'cash' },
          {
            label: 'Credit',
            name: 'typeOfAccount',
            value: 'credit',
          },
        ],
      },
      {
        label: 'ABN/NZBN*',
        placeholder: '01234',
        type: 'search',
        name: 'abn',
        data: [],
      },
      {
        label: 'ACN/NCN*',
        placeholder: '01234',
        type: 'search',
        name: 'acn',
        data: [],
      },
      {
        label: 'Business Structure',
        placeholder: 'Business Structure',
        type: 'select',
        name: 'businessStructure',
        data: entityType?.map(e => ({ ...e, name: 'businessStructure' })),
      },
      {
        label: 'Year of inception',
        placeholder: 'Year of inception',
        type: 'date',
        name: 'yearOfInception',
      },
      {
        label: 'Are You a New Applicant or an Existing Customer? *',
        placeholder: 'New or an Existing Customer?',
        type: 'select',
        name: 'newOrExistingApplicant',
        data: [
          { label: 'New Applicant', name: 'newOrExistingApplicant', value: 'newApplicant' },
          {
            label: 'Existing Account',
            name: 'newOrExistingApplicant',
            value: 'existingAccount',
          },
        ],
      },
      {
        label: `How Did You Hear About us?`,
        placeholder: 'How Did You Hear About us?',
        type: 'select',
        name: 'reference',
        data: [
          { label: 'Account manager', name: 'reference', value: 'accountManager' },
          { label: 'Recommendation', name: 'reference', value: 'recommendation' },
          { label: 'Trade store or truss and frame plant', name: 'reference', value: 'tradeStore' },
          { label: 'Newspaper/magazine', name: 'reference', value: 'newspaperMagazine' },
          { label: 'Google', name: 'reference', value: 'google' },
          { label: 'Event', name: 'reference', value: 'event' },
          { label: 'Billboard', name: 'reference', value: 'billboard' },
          { label: 'Radio', name: 'reference', value: 'radio' },
        ],
      },
      {
        type: 'section',
        mainTitle: 'Names',
      },
      {
        label: 'Legal Entity Name *',
        placeholder: 'Legal Entity Name',
        type: 'text',
        name: 'legalEntityName',
      },
      {
        label: 'Main Trading Name *',
        placeholder: 'Main Trading Name',
        type: 'text',
        name: 'mainTradingName',
      },
      {
        label: 'Other Trading Names',
        placeholder: 'Other Trading Names',
        type: 'text',
        name: 'otherTradingNames',
      },
      {
        type: 'section',
        mainTitle: 'Account Details',
      },
      {
        label: 'Estimated Monthly Purchases *',
        placeholder: 'Estimated Monthly Purchases',
        type: 'text',
        name: 'estimatedMonthlyPurchases',
      },
      {
        label: 'Customer Group: eg  *',
        placeholder: 'Customer Group: eg Builder',
        type: 'text',
        name: 'customerGroup',
      },
      {
        label: 'Home Branch *',
        placeholder: 'Select',
        type: 'select',
        name: 'homeBranch',
        data: [
          { label: 'NSW ALBURY', name: 'homeBranch', value: 'NSW_ALBURY' },
          { label: 'NSW ALEXANDRIA', name: 'homeBranch', value: 'NSW_ALEXANDRIA' },
          { label: 'NSW DENILIQUIN', name: 'homeBranch', value: 'NSW_DENILIQUIN' },
          { label: 'NSW GOSFORD', name: 'homeBranch', value: 'NSW_GOSFORD' },
          { label: 'NSW GRIFFITH', name: 'homeBranch', value: 'NSW_GRIFFITH' },
          { label: 'NSW KIRRAWEE', name: 'homeBranch', value: 'NSW_KIRRAWEE' },
          { label: 'NSW MACARTHUR', name: 'homeBranch', value: 'NSW_MACARTHUR' },
          { label: 'NSW MORRISET', name: 'homeBranch', value: 'NSW_MORRISET' },
          { label: 'NSW NOWRA', name: 'homeBranch', value: 'NSW_NOWRA' },
          { label: 'NSW RIVERSTONE', name: 'homeBranch', value: 'NSW_RIVERSTONE' },
          { label: 'NSW RYDALMERE', name: 'homeBranch', value: 'NSW_RYDALMERE' },
          { label: 'NSW SMEATON GRANGE', name: 'homeBranch', value: 'NSW_SMEATON_GRANGE' },
          { label: 'NSW ULLADULLA', name: 'homeBranch', value: 'NSW_ULLADULLA' },
          { label: 'NSW WAGGA WAGGA', name: 'homeBranch', value: 'NSW_WAGGA_WAGGA' },
          { label: 'NSW WYONG', name: 'homeBranch', value: 'NSW_WYONG' },
          { label: 'NSW SMITHFIELD', name: 'homeBranch', value: 'NSW_SMITHFIELD' },
          { label: 'VIC BAIRNSDALE', name: 'homeBranch', value: 'VIC_BAIRNSDALE' },
          { label: 'VIC BALLARAT', name: 'homeBranch', value: 'VIC_BALLARAT' },
          { label: 'VIC BAYSWATER', name: 'homeBranch', value: 'VIC_BAYSWATER' },
          { label: 'VIC CRANBOURNE', name: 'homeBranch', value: 'VIC_CRANBOURNE' },
          { label: 'VIC ECHUCA', name: 'homeBranch', value: 'VIC_ECHUCA' },
          { label: 'VIC LAKES ENTRANCE', name: 'homeBranch', value: 'VIC_LAKES_ENTRANCE' },
          { label: 'VIC LAVERTON', name: 'homeBranch', value: 'VIC_LAVERTON' },
          { label: 'VIC MILDURA', name: 'homeBranch', value: 'VIC_MILDURA' },
          { label: 'VIC MYRTLEFORD', name: 'homeBranch', value: 'VIC_MYRTLEFORD' },
          { label: 'VIC PAKENHAM', name: 'homeBranch', value: 'VIC_PAKENHAM' },
          { label: 'VIC SALE', name: 'homeBranch', value: 'VIC_SALE' },
          { label: 'VIC SHEPPARTON', name: 'homeBranch', value: 'VIC_SHEPPARTON' },
          { label: 'VIC SWAN HILL', name: 'homeBranch', value: 'VIC_SWAN_HILL' },
          { label: 'VIC THOMASTOWN', name: 'homeBranch', value: 'VIC_THOMASTOWN' },
          { label: 'VIC TRARALGON', name: 'homeBranch', value: 'VIC_TRARALGON' },
          { label: 'VIC WARRAGUL', name: 'homeBranch', value: 'VIC_WARRAGUL' },
          { label: 'ACT FYSHWICK', name: 'homeBranch', value: 'ACT_FYSHWICK' },
          { label: 'ACT MITCHELL', name: 'homeBranch', value: 'ACT_MITCHELL' },
          { label: 'ACT TRUSSME HUME', name: 'homeBranch', value: 'ACT_TRUSSME_HUME' },
        ],
      },
      {
        label: 'Offer Code',
        placeholder: 'Offer Code',
        type: 'text',
        name: 'offerCode',
      },
    ],
    [entityType, businessDetails?.notFoundAddress, _id],
  );

  const updateSingleApplicationState = useCallback((name, value) => {
    dispatch(updateEditApplicationField('businessDetails', name, value));
  }, []);

  const handleTextInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      updateSingleApplicationState(name, value);
    },
    [updateSingleApplicationState],
  );

  const handleSelectInputChange = useCallback(
    data => {
      updateSingleApplicationState(data?.name, data);
    },
    [updateSingleApplicationState],
  );

  const onChangeDate = useCallback(
    (name, date) => {
      const formattedDate =
        date instanceof Date
          ? `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(
              2,
              '0',
            )}-${date.getFullYear()}`
          : date;

      updateSingleApplicationState(name, formattedDate);
      console.log('hey', formattedDate);
    },
    [updateSingleApplicationState],
  );

  const getComponentFromType = useCallback(
    input => {
      let component = null;

      switch (input.type) {
        case 'text':
          component = (
            <Input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={businessDetails[input?.name] ?? ''}
              onChange={handleTextInputChange}
            />
          );
          break;
        case 'search':
          component = (
            <Input
              type="text"
              name={input.name}
              suffix="search"
              suffixClass="application-search-suffix"
              suffixClick={() => {}}
              placeholder={input.placeholder}
              value={businessDetails?.[input.name] ?? ''}
              onChange={handleTextInputChange}
              onKeyDown={() => {}}
            />
          );
          break;
        case 'select': {
          component = (
            <Select
              placeholder={input.placeholder}
              name={input?.name}
              options={input?.data ?? []}
              isSearchable
              value={businessDetails?.[input?.name] ?? []}
              onChange={handleSelectInputChange}
              onInputChange={input?.onInputChange}
            />
          );
          break;
        }
        case 'date':
          component = (
            <div className="date-picker-container">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText={input.placeholder}
                selected={businessDetails?.[input?.name] && new Date(businessDetails?.[input?.name])}
                onChange={date => onChangeDate(input?.name, date)}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                popperProps={{ positionFixed: true }}
              />
              <span className="material-icons-round">event</span>
            </div>
          );
          break;
        default:
          return (
            <div className="application-stepper-divider">
              <div className="application-company-step--main-title">{input?.mainTitle}</div>
            </div>
          );
      }
      if (input.hidden) {
        return null;
      }
      return (
        <React.Fragment key={input?.label}>
          {input.type === 'button' ? <div /> : <span>{input.label}</span>}
          {component}
          {businessDetails?.errors?.[input?.name] && (
            <div className={`ui-state-error ${input?.isOr && 'mt-10'}`}>{businessDetails?.errors?.[input?.name]}</div>
          )}
        </React.Fragment>
      );
    },
    [businessDetails, handleSelectInputChange, handleTextInputChange, updateSingleApplicationState],
  );

  useEffect(() => {
    if (_id) {
      dispatch(getApplicationCompanyDropDownData(_id)).catch(() => {
        errorNotification('Error during fetching data');
      });
    }
    return () => dispatch(updateEditApplicationData('company', { errors: {} }));
  }, [_id]);

  return (
    <>
      <div className="application-company-container">{INPUTS?.map(getComponentFromType)}</div>
    </>
  );
};

export default PublicApplicationBusinessDetailsStep;
